import Axios from "axios";

const actions = {
  ///////////////////////////////////////////////////  QueTable  /////////////////////////////////////////////////////////////
  async actGetData(store, data) {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/price`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },  
  ///////////////////////////////////////////////////  QueTable  /////////////////////////////////////////////////////////////
};

export default actions;
