import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSSE from "vue-sse";
import VueTextToSpeech from "vue-text-to-speech";
import mixinJS from './js/mixin';
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueAxios, axios);
Vue.use(require("vue-moment"));
Vue.use(VueSweetalert2);
Vue.use(VueSSE);
Vue.use(VueTextToSpeech);
Vue.mixin(mixinJS);
Vue.use(VueSimpleAlert);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
